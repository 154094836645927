html {
  font-family: Inter;
}

body {
  background-color: #22242a;
  color: white;
  display: grid;
  place-items: center;
  text-align: center;
  height: 100dvh;
}

.board {
  padding: 15px;
  gap: 15px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  background: #1b1e22;
  border-radius: 10px;
}

.tile {
  height: 50px;
  aspect-ratio: 1;
  background-color: #22242a;
  border-radius: 10px;
  display: grid;
  place-items: center;
  cursor: pointer;
  padding: 10px;
  font-weight: 600;
  font-size: 20px;
}
.clicked div {
  background-color: #1777ff;
  display: grid;
  place-items: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
